<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-10-29 16:06:17
 * @Description: 服务类型添加/编辑
-->
<template>
  <div>
    <div class="header">
      <ds-header :title="headerTitle" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :rules="rules">
      <a-form-model-item label="所属项目" prop="projectIdList">
        <!-- <a-select
          @change="changeProject"
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 800px"
          placeholder="请选择所属项目"
          v-model="form.projectIdList"
        >
          <a-select-option v-for="item in projectList" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <el-cascader v-model="form.projectIdList"
              ref="cascader"
              style="width: 800px"
              :collapse-tags = "true"
              :options="projectList"
              @change="changeProject"
              :props="{emitPath:false, multiple: true,checkStrictly:true, label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable>
        </el-cascader>
      </a-form-model-item>
      <a-form-model-item label="服务类型名称" prop="serverTypeName">
        <a-input
          style="width: 800px"
          placeholder="请输入服务类型名称，不超过10个字符"
          :max-length="10"
          v-model.trim="form.serverTypeName"
        />
      </a-form-model-item>
      <a-form-model-item label="服务类型LOGO上传" prop="serverTypeLogo">
        <a-upload
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :action="IMG_API + '/oss/files'"
          :before-upload="beforeUploadLogo"
          @change="handleChangeLogo"
          accept=".png,.jpg,.jpeg"
        >
          <img class="upImg" v-if="form.serverTypeLogo" :src="form.serverTypeLogo" alt="avatar" />
          <div v-else>
            <a-icon type="plus" />
          </div>
        </a-upload>

        <span class="tips-color">图片类型支持jpg/png/jpeg类型，大小不超过200KB，比例1:1</span>
        <div class="tips-color">请参照默认图片样式上传LOGO以保持界面统一</div>
      </a-form-model-item>
      <a-form-model-item label="服务展示图片上传" prop="serverTypeImage">
        <a-upload
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :action="IMG_API + '/oss/files'"
          :before-upload="beforeUploadType"
          @change="uploadSuccess"
          accept=".png,.jpg,.jpeg"
        >
          <img class="upImg" v-if="form.serverTypeImage" :src="form.serverTypeImage" alt="avatar" />
          <div v-else>
            <a-icon type="plus" />
          </div>
        </a-upload>
        <span class="tips-color">图片类型支持jpg/png/jpeg类型，大小不超过10M，建议比例1:5</span>
      </a-form-model-item>

      <a-form-model-item label="服务简介" :span="24" required>
        <div class="content">
          <div id="editer" style="width: 800px"></div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="是否展示供应商" :span="24" prop="isShowSupplier">
        <a-radio-group v-model="form.isShowSupplier">
          <a-radio :value="0"> 是 </a-radio>
          <a-radio :value="1"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="服务供应商" prop="supplierIdList" v-if="form.isShowSupplier === 0">
        <a-select
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 800px"
          placeholder="请选择服务供应商"
          v-model="form.supplierIdList"
        >
          <a-select-option
            v-for="item in supplierList"
            :key="item.supplierId"
            :value="item.supplierId"
            :disabled="item.supplerState != 0"
          >
            {{ item.supplierName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="服务模式" :span="24" prop="serviceModeTypeList">
        <a-checkbox-group  v-model="form.serviceModeTypeList">
          <a-checkbox :value="1"> 工单咨询 </a-checkbox>
          <a-checkbox :value="2"> 电话咨询 </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.serviceModeTypeList.includes(2)" label="联系电话" :span="24" prop="phoneNumber">
        <a-input  style="width: 800px" v-model="form.phoneNumber" placeholder="请输入联系电话"  :maxLength="30" />
      </a-form-model-item>
      <a-form-model-item v-if="form.serviceModeTypeList.includes(1) && !isTenant"  label="咨询受理人" :span="24" prop="serviceMode">
        <a-radio-group v-model="form.serviceMode">
          <a-radio  :value="0"> 仅Fellow </a-radio>
          <a-radio  :value="1"> Fellow+运营 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="状态" :span="24" prop="serverTypeStatus">
        <a-radio-group v-model="form.serverTypeStatus">
          <a-radio :value="0"> 启用 </a-radio>
          <a-radio :value="1"> 禁用 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 8 }">
        <a-button type="primary" @click="submit" style="margin-right: 20px"> 确定 </a-button>
        <a-button type="info" @click="goBack"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import wangEditor from "wangeditor";
import * as api from "@/api/entrepreneurClubManagement/serverManagement";
import debounce from "lodash/debounce";
export default {
  name: "serverTypeAdd",
  data() {
    return {
      IMG_API,
      // 标题
      headerTitle: "",
      form: {
        // 服务类型标题
        serverTypeName: "",
        // 服务类型状态
        serverTypeStatus: 0,
        // 服务简介
        serverTypeIntroduce: "",
        // 所属项目
        projectIdList: undefined,
        // 服务展示图片
        serverTypeImage: "",
        // 服务类型LOGO
        serverTypeLogo: "",
        // 是否展示供应商
        isShowSupplier: 0,
        // 服务模式
        serviceMode: 0,
        // 服务供应商
        supplierIdList: [],
        // 服务模式
        serviceModeTypeList:[],
      },
      rules: {
        serverTypeName: [{ required: true, message: "请输入服务类型名称", trigger: "blur" }],
        serverTypeStatus: [{ required: true, message: "请选择状态", trigger: "change" }],
        projectIdList: [{ required: true, message: "请选择所属项目", trigger: "change" }],
        serverTypeLogo: [{ required: true, message: "请上传服务类型LOGO", trigger: "change" }],
        supplierIdList: [{ required: true, message: "请选择服务供应商", trigger: "change" }],
        isShowSupplier: [{ required: true, message: "请选择是否展示服务供应商", trigger: "change" }],
        serviceMode: [{ required: true, message: "请选择咨询受理人", trigger: "change" }],
        phoneNumber: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        serviceModeTypeList: [{ required: true, message: "请选择服务模式", trigger: "change" }],
      },
      // 项目
      projectList: [],
      // 服务供应商
      supplierList: [],
      isTenant:false
    };
  },
  created() {
    // 获取所属项目
    this.getProject();
    if (this.$route.query.type === "edit") {
      this.headerTitle = "编辑服务类型";
      this.getDetailList();
    } else {
      this.headerTitle = "新增服务类型";
      // 默认LOGO
      this.form.serverTypeLogo =
        "https://oss-workplace-prod.innoecos.cn/picture_prod/054437bb66410000_%E9%A3%9E%E4%B9%A620220813-143207.png";
    };
      // 是否是顺义
    api.isTenant().then(res=>{
      this.isTenant = res.data
      if(this.isTenant){
        // this.form.serviceMode = 10
      }
      console.log(res,'=======>');
    })
  },
  mounted() {
    const _this = this;
    const editor = new wangEditor(`#editer`);
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.form.serverTypeIntroduce = newHtml;
    };
    editor.config.menus = [
      "fontSize",
      "foreColor",
      "backColor",
      "underline",
      "italic",
      "bold",
      "justify",
      "splitLine",
      "undo",
      "redo",
      "list",
      "table",
      "image",
    ];
    editor.config.showFullScreen = false;
    editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
    editor.config.uploadImgServer = this.IMG_API + "/oss/files";
    editor.config.uploadImgShowBase64 = false;
    editor.config.showLinkImg = false;
    editor.config.showLinkVideo = false;
    editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
    editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
    editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
    // editor.config.uploadVideoAccept = ['mp4']
    editor.config.uploadFileName = "file";
    editor.config.uploadVideoName = "file";
    editor.config.uploadVideoTimeout = 60000;
    editor.config.uploadImgTimeout = 60000;
    editor.config.uploadVideoHooks = {
      // 上传视频之前
      before: function (xhr, editor, files) {
        console.log(xhr);
        console.log(editor);
        console.log(files);
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function (xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频超时
      timeout: function (xhr) {
        _this.$message.error("上传请求超时");
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function (insertVideoFn, result) {
        let videoHTML =
          '&nbsp;<video poster="' +
          result.redirect_uri +
          '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
          result.redirect_uri +
          '" controls style="max-width:100%"></video>&nbsp;';
        editor.cmd.do("insertHTML", videoHTML);
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        // insertVideoFn(result.redirect_uri)
      },
    };
    editor.config.uploadImgHooks = {
      // 上传视频之前
      before: function (xhr) {},
      // 视频上传并返回了结果，视频插入已成功
      success: function (xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频超时
      timeout: function (xhr) {
        _this.$message.error("上传请求超时");
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function (insertVideoFn, result) {
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.redirect_uri);
      },
    };
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case "success":
          _this.$message.success(s);
          break;
        case "info":
          _this.$message.info(s);
          break;
        case "warning":
          _this.$message.warning(s);
          break;
        case "error":
          _this.$message.error(s);
          break;
        default:
          _this.$message.info(s);
          break;
      }
    };
    editor.config.placeholder = "请输入服务简介";
    // 创建编辑器
    editor.create();
    this.editor = editor;
    // 查看的时候禁用富文本
    if (this.$route.query.type === "view") {
      this.editor.disable();
    } else {
      this.editor.enable();
    }
  },
  methods: {
    // 获取详情
    async getDetailList() {
      try {
        const res = await api.serverTypeDetail(this.$route.query.id);
        if (res.code === "200") {
          res.data.projectIdList = [];
          this.form = res.data;
          this.editor.txt.html(this.form.serverTypeIntroduce);
          this.form.projectInfo.forEach((item) => {
            this.form.projectIdList.push(item.projectId);
          });
          this.form.supplierIdList = this.form.supplierIdList === null ? [] : this.form.supplierIdList;
          this.getSupplier(this.form.projectIdList);
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 确定
    submit: debounce(function () {
      let obj = {
        ...this.form,
      };
      if (obj.serverTypeIntroduce === "") {
        this.$message.error("请填写服务简介");
        return;
      }
      if(!this.form.serviceModeTypeList.includes(2)){
        obj.phoneNumber = ''
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.$route.query.id) {
            const res = await api.serverTypeEdit(obj);
            if (res.code === "200") {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          } else {
            const res = await api.serverTypeAdd(obj);
            if (res.code === "200") {
              this.$message.success("添加成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          }
        }
      });
    }, 300),
    // 取消
    goBack() {
      this.$router.go(-1);
    },
    // 上传LOGO类型校验
    beforeUploadLogo(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png、jpeg格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 <= 200;
      if (!isLt2M) {
        this.$message.error("图片最大200KB");
        return Promise.reject();
      }
      // 校验比例
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === img.height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          return Promise.reject();
        }
      );
      return isJpgOrPng && isLt2M && isSize;
    },
    // 服务图片校验
    beforeUploadType(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png、jpeg格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 / 1024 <= 10;
      if (!isLt2M) {
        this.$message.error("图片最大10MB");
        return Promise.reject();
      }
      return isJpgOrPng && isLt2M;
    },
    // 服务图片上传完成
    uploadSuccess(info) {
      if (info.file.status === "done") {
        this.form.serverTypeImage = info.file.response.redirect_uri;
      }
    },
    // LOGO上传完成
    handleChangeLogo(info) {
      if (info.file.status === "done") {
        this.form.serverTypeLogo = info.file.response.redirect_uri;
      }
    },
    // 获取所属项目
    async getProject() {
      try {
        const res = await api.getShunyiAllProject({check_type:true});
        if (res.code === "200") {
          this.projectList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    // 通过选择项目获取 服务供应商
    changeProject() {
      this.getSupplier(this.form.projectIdList);
    },
    // 获取服务供应商
    async getSupplier(value) {
      try {
        const res = await api.selectSupplierList({ projectIdList: value });
        if (res.code === "200") {
          this.supplierList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}

::v-deep .w-e-toolbar {
  z-index: 3 !important;
}

::v-deep .w-e-text-container {
  z-index: 1 !important;
}
::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.upImg {
  width: 109px;
  height: 109px;
}
</style>